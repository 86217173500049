<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        v-if="!isPast"
        dark
        text
        class="orange--text text-h6 font-weight-bold"
        @click.native="agree"
      >등록</v-btn>

      <v-btn
        v-if="index > -1 && !isPast"
        slot="func-button"
        dark
        text
        :disabled="isRemovable"
        class="pink--text text--lighten-2 text-h6"
        @click.native="remove"
      >삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark
        text
        class="white--text subheading"
        @click.native="cancel"
      >
        {{ isPast ? '확인' : '취소' }}
      </v-btn>

      <v-checkbox
        v-if="!isPast"
        slot="func-option"
        v-model="addWorkContent"
        dense
        label="업무추가"
        class="mt-3"
      ></v-checkbox>

      <!-- <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn> -->

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <!-- 선택된 회의실: 수정불가 -->
            <v-col cols="12" xs="12" class="pb-2">
              <v-text-field
                v-model.trim="this.meetroomName"
                label="대상 회의실"
                prepend-icon="mdi-lock"
                readonly
                background-color="grey lighten-3"
                class="mt-1"
              ></v-text-field>
            </v-col>
            <!-- 담당부서: 담당부서를 수정가능하게 다시 변경 -->
            <v-col cols="12" xs="12" class="mt-0 pb-2">
              <v-select
                v-model="form.teamId"
                :items="teams"
                item-text="text"
                item-value="value"
                label="담당부서"
                v-validate="'required|decimal|greaterThanZero'"
                :error-messages="errors.collect('teamId')"
                data-vv-name="teamId"
                required
                dense
                menu-props="auto"
                class="mt-4"
                :disabled="isPast"
              ></v-select>
            </v-col>
            <!-- 일자선택 -->
            <v-col cols="4" xs="12" class="pb-2">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :disabled="index > -1 || isPast"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="pickerDate"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="width: 180px;"
                    :disabled="index > -1 || isPast"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="pickerDate"
                  locale="ko"
                  :allowed-dates="allowedDates"
                  @change="saveDate1"
                  :readonly="isPast"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- 시간선택 -->
            <v-col cols="8" xs="12" class="pb-2 pl-4">
               <v-text-field
                v-model="form.str4"
                v-validate="'required'"
                :error-messages="errors.collect('fromTimeToTime')"
                data-vv-name="fromTimeToTime"
                readonly
                :disabled="isPast"
                label="예약시간"
              >
                <template v-slot:prepend-inner>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small
                        class="mt-1 mr-2"
                        style="cursor: pointer;"
                        @click="initSelTimeMinute"
                      >
                        mdi-refresh
                      </v-icon>
                    </template>
                    <span>선택시간 초기화</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12" class="pb-2">
              <v-row no-gutters>
                <h4 class="mb-1">예약시간선택</h4>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="primary" dark small class="mb-1 ml-1">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>연속된 시간을 선택해 주십시오. 선택을 취소할 때도 마찬가지 입니다.</span>
                </v-tooltip>
              </v-row>
              <v-sheet height="450">
                <div class="w-calendar w-calendar-daily w-theme--light">
                  <div class="w-calendar-daily__head" style="margin-right: 15px;">
                    <div class="w-calendar-daily__intervals-head" style="width: 60px;"></div>
                  </div>
                  <div class="w-calendar-daily__body">
                    <!-- @: scroll 영역 -->
                    <div id="w-scrollTop" class="w-calendar-daily__scroll-area">
                      <!-- <div class="w-calendar-daily__pane" style="height: 1152px;"> -->
                      <div class="w-calendar-daily__pane" style="height: 1728px;">
                        <div class="w-calendar-daily__day-container">
                          <!-- 시간선택의 좌측 AM/PM 영역 -->
                          <div class="w-calendar-daily__intervals-body" style="width: 66px;">
                            <div class="w-calendar-daily__interval" style="height: 67px;">
                              <div class="w-calendar-daily__interval-text"></div>
                            </div>
                            <template v-for="item in tAMPM">
                              <div
                                :key="item"
                                class="w-calendar-daily__interval" style="height: 72px;">
                                <div class="w-calendar-daily__interval-text">
                                  {{ item }}
                                </div>
                              </div>
                            </template>
                          </div>
                          <!--  @: 시간선택의 우측 선택 영역 -->
                          <div class="w-calendar-daily__day">
                            <template
                              v-for="n in [0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46]"
                            >
                              <div :Key="n"
                                class="w-calendar-daily__day-interval"
                                style="height: 72px;"
                              >
                                <!-- // ? 과거인 경우: 선택 불가 -->
                                <template v-if="isPast">
                                  <div class="text-center">
                                    <v-btn
                                      block
                                      text
                                      :disabled="!arrTimes[n].isChoice"
                                      :readonly="arrTimes[n].isChoice"
                                      :color="arrTimes[n].isChoice ? 'red lighten-3' : ''"
                                    >
                                      <v-icon small left class="mr-2">
                                        {{ arrTimes[n].isChoice ? 'mdi-checkbox-marked-outline' : arrTimes[n].disabled ? 'mdi-checkbox-blank' : 'mdi-checkbox-blank-outline' }}
                                      </v-icon>
                                      {{ arrTimes[n].text }}
                                    </v-btn>
                                  </div>
                                  <v-divider></v-divider>
                                  <div class="text-center">
                                    <v-btn
                                      block
                                      text
                                      :disabled="!arrTimes[n + 1].isChoice"
                                      :readonly="arrTimes[n + 1].isChoice"
                                      :color="arrTimes[n + 1].isChoice ? 'red lighten-3' : ''"
                                    >
                                      <v-icon small left class="mr-2">
                                        {{ arrTimes[n + 1].isChoice ? 'mdi-checkbox-marked-outline' : arrTimes[n + 1].disabled ? 'mdi-checkbox-blank' : 'mdi-checkbox-blank-outline' }}
                                      </v-icon>
                                      {{ arrTimes[n + 1].text }}
                                    </v-btn>
                                  </div>
                                </template>
                                <!-- // ? 과거가 아닌 경우: 선택 가능 -->
                                <template v-else>
                                  <div class="text-center">
                                    <v-btn
                                      block
                                      text
                                      :disabled="arrTimes[n].disabled"
                                      :color="arrTimes[n].isChoice ? 'primary' : ''"
                                      @click.stop="setCheckItem(arrTimes[n])"
                                    >
                                      <v-icon small left class="mr-2">
                                        {{ arrTimes[n].isChoice ? 'mdi-checkbox-marked-outline' : arrTimes[n].disabled ? 'mdi-checkbox-blank' : 'mdi-checkbox-blank-outline' }}
                                      </v-icon>
                                      {{ arrTimes[n].text }}
                                    </v-btn>
                                  </div>
                                  <v-divider></v-divider>
                                  <div class="text-center">
                                    <v-btn
                                      block
                                      text
                                      :disabled="arrTimes[n + 1].disabled"
                                      :color="arrTimes[n + 1].isChoice ? 'primary' : ''"
                                      @click="setCheckItem(arrTimes[n + 1])"
                                    >
                                      <v-icon small left class="mr-2">
                                        {{ arrTimes[n + 1].isChoice ? 'mdi-checkbox-marked-outline' : arrTimes[n + 1].disabled ? 'mdi-checkbox-blank' : 'mdi-checkbox-blank-outline' }}
                                      </v-icon>
                                      {{ arrTimes[n + 1].text }}
                                    </v-btn>
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- !! 오버레이 영역 -->
                    <v-overlay absolute :value="timeOverlay" opacity="0.25">
                      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
                    </v-overlay>
                  </div>
                </div>
              </v-sheet>
            </v-col>

            <v-col cols="12">
              <div class="mb-6"></div>
            </v-col>
          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'

import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
// import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 0, // 중요: 초기는 선택으로 픽스
      type: 9, // * lwcommon.type='9'
      depth: 1,
      rId: 0, // * 회의실 아이디
      rInfo: '', // * 회의실정보 JSON 문자열
      isStar: 0,
      isSchedule: 0, // 일정표시여부
      status: 1,
      gubun1: '회의실예약',
      str1: '', // * fromTime
      str2: '', // * toTime
      str3: '', // * 예약 아이디 문자열 '15,16,17' 과 같은 문자열
      str4: '', // * from time ~ to time
      date1: '', // * 예약시작일시
      date2: '', // * 예약종료일시
      wemail: '',
      wname: ''
    },
    // 구분: date-picker 의 v-model 용 변수
    pickerDate: '',
    // 구분: 예약시간 선택시 좌측 타이틀 영역
    tAMPM: [
      '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
      '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'
    ],
    // 구분: 시간배열
    // * id: 순차처리를 편하게 하기위해
    // * isChoice: 단순히 선택됨/선택안됨
    // * disabled: 선택불가(예약된 경우)
    arrTimes: [
      { id: 1, value: '0000', ft: '00:00:01', et: '00:30:00', isChoice: false, disabled: false, text: '00:00' },
      { id: 2, value: '0030', ft: '00:30:01', et: '01:00:00', isChoice: false, disabled: false, text: '00:30' },
      { id: 3, value: '0100', ft: '01:00:01', et: '01:30:00', isChoice: false, disabled: false, text: '01:00' },
      { id: 4, value: '0130', ft: '01:30:01', et: '02:00:00', isChoice: false, disabled: false, text: '01:30' },
      { id: 5, value: '0200', ft: '02:00:01', et: '02:30:00', isChoice: false, disabled: false, text: '02:00' },
      { id: 6, value: '0230', ft: '02:30:01', et: '03:00:00', isChoice: false, disabled: false, text: '02:30' },
      { id: 7, value: '0300', ft: '03:00:01', et: '03:30:00', isChoice: false, disabled: false, text: '03:00' },
      { id: 8, value: '0330', ft: '03:30:01', et: '04:00:00', isChoice: false, disabled: false, text: '03:30' },
      { id: 9, value: '0400', ft: '04:00:01', et: '04:30:00', isChoice: false, disabled: false, text: '04:00' },
      { id: 10, value: '0430', ft: '04:30:01', et: '05:00:00', isChoice: false, disabled: false, text: '04:30' },
      { id: 11, value: '0500', ft: '05:00:01', et: '05:30:00', isChoice: false, disabled: false, text: '05:00' },
      { id: 12, value: '0530', ft: '05:30:01', et: '06:00:00', isChoice: false, disabled: false, text: '05:30' },
      { id: 13, value: '0600', ft: '06:00:01', et: '06:30:00', isChoice: false, disabled: false, text: '06:00' },
      { id: 14, value: '0630', ft: '06:30:01', et: '07:00:00', isChoice: false, disabled: false, text: '06:30' },
      { id: 15, value: '0700', ft: '07:00:01', et: '07:30:00', isChoice: false, disabled: false, text: '07:00' },
      { id: 16, value: '0730', ft: '07:30:01', et: '08:00:00', isChoice: false, disabled: false, text: '07:30' },
      { id: 17, value: '0800', ft: '08:00:01', et: '08:30:00', isChoice: false, disabled: false, text: '08:00' },
      { id: 18, value: '0830', ft: '08:30:01', et: '09:00:00', isChoice: false, disabled: false, text: '08:30' },
      { id: 19, value: '0900', ft: '09:00:01', et: '09:30:00', isChoice: false, disabled: false, text: '09:00' },
      { id: 20, value: '0930', ft: '09:30:01', et: '10:00:00', isChoice: false, disabled: false, text: '09:30' },
      { id: 21, value: '1000', ft: '10:00:01', et: '10:30:00', isChoice: false, disabled: false, text: '10:00' },
      { id: 22, value: '1030', ft: '10:30:01', et: '11:00:00', isChoice: false, disabled: false, text: '10:30' },
      { id: 23, value: '1100', ft: '11:00:01', et: '11:30:00', isChoice: false, disabled: false, text: '11:00' },
      { id: 24, value: '1130', ft: '11:30:01', et: '12:00:00', isChoice: false, disabled: false, text: '11:30' },
      { id: 25, value: '1200', ft: '12:00:01', et: '12:30:00', isChoice: false, disabled: false, text: '12:00' },
      { id: 26, value: '1230', ft: '12:30:01', et: '13:00:00', isChoice: false, disabled: false, text: '12:30' },
      { id: 27, value: '1300', ft: '13:00:01', et: '13:30:00', isChoice: false, disabled: false, text: '13:00' },
      { id: 28, value: '1330', ft: '13:30:01', et: '14:00:00', isChoice: false, disabled: false, text: '13:30' },
      { id: 29, value: '1400', ft: '14:00:01', et: '14:30:00', isChoice: false, disabled: false, text: '14:00' },
      { id: 30, value: '1430', ft: '14:30:01', et: '15:00:00', isChoice: false, disabled: false, text: '14:30' },
      { id: 31, value: '1500', ft: '15:00:01', et: '15:30:00', isChoice: false, disabled: false, text: '15:00' },
      { id: 32, value: '1530', ft: '15:30:01', et: '16:00:00', isChoice: false, disabled: false, text: '15:30' },
      { id: 33, value: '1600', ft: '16:00:01', et: '16:30:00', isChoice: false, disabled: false, text: '16:00' },
      { id: 34, value: '1630', ft: '16:30:01', et: '17:00:00', isChoice: false, disabled: false, text: '16:30' },
      { id: 35, value: '1700', ft: '17:00:01', et: '17:30:00', isChoice: false, disabled: false, text: '17:00' },
      { id: 36, value: '1730', ft: '17:30:01', et: '18:00:00', isChoice: false, disabled: false, text: '17:30' },
      { id: 37, value: '1800', ft: '18:00:01', et: '18:30:00', isChoice: false, disabled: false, text: '18:00' },
      { id: 38, value: '1830', ft: '18:30:01', et: '19:00:00', isChoice: false, disabled: false, text: '18:30' },
      { id: 39, value: '1900', ft: '19:00:01', et: '19:30:00', isChoice: false, disabled: false, text: '19:00' },
      { id: 40, value: '1930', ft: '19:30:01', et: '20:00:00', isChoice: false, disabled: false, text: '19:30' },
      { id: 41, value: '2000', ft: '20:00:01', et: '20:30:00', isChoice: false, disabled: false, text: '20:00' },
      { id: 42, value: '2030', ft: '20:30:01', et: '21:00:00', isChoice: false, disabled: false, text: '20:30' },
      { id: 43, value: '2100', ft: '21:00:01', et: '21:30:00', isChoice: false, disabled: false, text: '21:00' },
      { id: 44, value: '2130', ft: '21:30:01', et: '22:00:00', isChoice: false, disabled: false, text: '21:30' },
      { id: 45, value: '2200', ft: '22:00:01', et: '22:30:00', isChoice: false, disabled: false, text: '22:00' },
      { id: 46, value: '2230', ft: '22:30:01', et: '23:00:00', isChoice: false, disabled: false, text: '22:30' },
      { id: 47, value: '2300', ft: '23:00:01', et: '23:30:00', isChoice: false, disabled: false, text: '23:00' },
      { id: 48, value: '2330', ft: '23:30:01', et: '23:59:59', isChoice: false, disabled: false, text: '23:30' }
    ],
    // 구분: 예약 문자열 - DB에서 패칭해온다.
    rsrved: '000000000000000000000000000000000000000000000000',
    // 구분: 현재 선택된 시간배열
    selTimes: [],
    // 구분: 선택된 시간의 id 배열
    selIds: [],
    // 구분: 예약된 시간의 id 배열 - 백앤드에서 넘어와서 처리됨
    reservedIds: [],
    // 구분: 테스트용 변수
    // fromTimeToTime: '',
    // 구분: 부모로부터 선택된 회의실정보 객체
    meetroom: null,
    // 구분: 선택된 회의실 이름
    meetroomName: '',
    // 구분:
    teams: [], // 소속팀
    // 구분: 시간선택영역 오버레이
    timeOverlay: false,
    // 구분: 수정 인덱스
    index: -1,
    // 구분: 수정시 해당 일자는 이미 지났음을 나타내는 여부
    isPast: false,
    // 구분: 수정시 현재 예약을 삭제할 수 있는지 여부
    isRemovable: false,
    // 구분: 업무추가 체크박스
    addWorkContent: false,
    // 구분: 수정시 사용하는 팀정보
    teamInfo: {
      name: '',
      color: ''
    },
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        teamId: '담당부서',
        fromTimeToTime: '예약시간'
      }
    },
    // 구분: date-picker 일자
    date1Selector: false
    // 구분: 입력 검증
    // rules: {
    //   txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    // }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  created () {
    this.$validator.extend(
      'greaterThanZero',
      {
        getMessage: field => field + ' 를 선택해야 합니다!',
        validate: (value) => {
          // value must be > zero
          if (value > 0) return true
          return false
        }
      })
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 객체를 뷰해주는 헬퍼함수
    consoleObject (obj) {
      // for ~ in 으로 객체 순환
      // for (let key in obj) {
      //   console.log(`[${typeof obj[key]}]${key}: ${obj[key]}`)
      // }
      // JSON.stringfy() 로 변환
      console.log(JSON.stringify(obj, null, 2))
    },
    // 구분: 자신이 속한 팀을 가져온다
    // 기본팀은 필수, (관리자팀,물품구매팀,회의실관리팀은 제외)
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        // 중요: 맨 앞에 '선택' 을 넣는다.
        data.teams.unshift({ text: '선택', value: 0 })
        // console.log(data.teams)

        this.form.teamId = data.teams[0].value

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.meetroomName = ''

      this.form.id = 0
      this.form.teamId = 0 // 중요: 팀아이디 초기값
      this.form.type = 9 // * lwcommon.type='9'
      this.form.depth = 1

      this.form.rId = 0
      this.form.rInfo = ''

      this.form.isStar = 0
      this.form.isSchedule = 0 // 일정표시여부
      this.form.status = 1
      this.form.gubun1 = '회의실예약'
      this.form.str1 = '' // * fromTime
      this.form.str2 = '' // * toTime
      this.form.str3 = '' // * 예약 아이디 문자열 '11,12,13' 과 같은 문자열
      this.form.str4 = '' // * from time ~ to time 문자열

      this.form.date1 = this.$moment().format('YYYY-MM-DD 00:00:00') // 예약시작일시
      this.form.date2 = this.$moment().format('YYYY-MM-DD 00:00:00') // 예약종료일시

      this.form.wemail = this.$store.state.ui.email || '' // 예약 수정자 이메일(로그인 유저)
      this.form.wname = this.$store.state.ui.name || '' // 예약 수정자 이름(로그인 유저)

      // * date-picker 에서 쓰이는 v-model용 변수 초기화
      this.pickerDate = this.$moment().format('YYYY-MM-DD')

      // ! 중요: 시간관련 변수(배열) 초기화
      // * saveDate1() 에서도 적용해야 한다.
      this.arrTimes.map(arr => {
        arr.isChoice = false
        arr.disabled = false
      })
      this.selTimes = [] // 선택된 시간배열 초기화
      this.selIds = [] // 선택된 시간 id 배열 초기화
      this.reservedIds = [] // 예약된 시간 id 배열 초기화

      // this.fromTimeToTime = '' // 뷰해주는 변수

      this.index = -1 // * 수정용 인덱스 초기화
      this.isPast = false // * 지난 일자의 여부
      this.isRemovable = false // * 삭제 가능 여부

      this.addWorkContent = false // * 업무추가 체크박스
    },
    // 구분: 오픈시 로딩되는 함수
    // * index 는 수정인 경우 1로 넘어온다. 등록은 -1
    // * isPast = true 로 넘어오면 이미 지난 날짜인 경우를 나타낸다.
    // * meetroom 는 넘겨받은 회의실 정보다
    // * rsvDate [2022.9.6] 예약 등록시 일정달력으로 부터 날자를 넘겨받은 데이터임
    async open (title, options, index = -1, isPast = false, meetroom, obj = {}, rsvDate) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.initDialog()

        // * 자신이 속한 팀리스트를 가져온다
        this.teams = await this.getTeams()

        // * 부모로 부터 넘겨받은 선택된 회의실 정보를 rId, rInfo 에 담는다.
        this.meetroom = meetroom
        if (!this.meetroom) throw new Error('선택된 회의실 정보가 없습니다.')
        // console.log(this.meetroom.name)
        this.meetroomName = this.meetroom.name

        // * 부모정보: 회의실 아이디와 회의실정보(JSON문자열)
        this.form.rId = this.meetroom.id
        this.form.rInfo = JSON.stringify(this.meetroom)

        // ![2022.9.6] 예약 등록시 월간 달력으로 부터 넘겨받은 날짜로 세팅한다.
        if (rsvDate) {
          this.pickerDate = rsvDate
        }

        // 참고: 수정 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // this.consoleObject(obj)
          // this.consoleObject(this.teams)
          this.index = index

          this.isPast = isPast // ! 이미 지난 날자인지 여부

          // * [2022.8.5] date-picker 에 변수 매칭하기
          this.pickerDate = obj.date1.substr(0, 10)

          this.form.id = obj.id
          this.form.teamId = obj.teamId // 중요: 매칭하지 않으면 사라져 버린다!
          // this.form.status = obj.status
          // this.form.gubun1 = obj.gubun1
          // this.form.isStar = obj.isStar

          this.form.str1 = obj.str1 // * fromTime 시-분 '09:00:01'
          this.form.str2 = obj.str2 // * toTime 시-분 '10:30:00'
          this.form.str3 = obj.str3 // * 예약된 아이디 문자열 '21,3,'
          this.form.str4 = obj.str4 // * from time to time 문자열

          this.form.date1 = obj.date1
          this.form.date2 = obj.date2

          // ! [2022.9.2] 수정시 삭제된 부서인 경우 처리. 부서 셀렉트의 첫번째를 강제로 만들고 선택시킨다.
          if (obj.teamName === '***') {
            this.teams[0] = { text: '삭제된 부서입니다', value: 0 }
            this.form.teamId = 0
          }

          // * 팀명과 팀컬러 처리
          this.teamInfo.name = obj.teamName
          this.teamInfo.color = obj.teamColor

          // * 중요: 예약현황을 패칭해서 적용한다.
          await this.getReservedDateTime()

          // # 중요: 선택된 시간은 this.arrTimes 에서 disabled 된다.
          // # 그러나 수정시 현재 article의 선택 시간을 편집가능하게 만들어야 한다.
          // * this.selIds, this.selTimes 에 선택 시간 매칭시키기
          this.selIds = []
          this.selTimes = []
          const ops = this.form.str3.split(',')
          const startOp = Number(ops[0]) // 시작 id 번호
          const lengthOp = Number(ops[1]) // 길이
          for (let i = 0; i < lengthOp; i++) {
            this.selIds.push(startOp + i)
          }

          // * this.selIds 를 순회하면서 선택가능하게 한다.
          this.selIds.forEach(tId => {
            this.arrTimes[tId - 1].disabled = false // 선택불가를 풀고
            this.arrTimes[tId - 1].isChoice = true // 선택된 것으로 만든다.
            //
            this.selTimes.push(this.arrTimes[tId - 1]) // this.selTimes 에 넣는다.
          })

          // * 예약 배열인(this.reservedIds)에 차집합을 넣어줘야 한다. 예약 배열에서 빼버린다.
          this.reservedIds = this.reservedIds.filter(x => !this.selIds.includes(x))
          //
        } else {
          // ! 등록시 바로 시간선택 적용
          // * 중요: 예약현황을 패칭해서 적용한다.
          await this.getReservedDateTime()
        }
        // this.consoleObject(this.form) // view this.form object

        // *
        this.$nextTick(() => {
          // 스크롤 자동 이동 -- dialog 에선 안됨
          // this.$vuetify.goTo('#w-scrollTop', { duration: 300, offset: -100, easing: 'linear' })
          // 이것도 안되네
          // document.getElementById('w-scrollTop').scrollIntoView({ behavior: 'smooth' })
          // * 스크롤 자동 이동 - 08:00 부터 시작하게
          document.getElementById('w-scrollTop').scrollTop = 580

          // 사용안함: 예약된 시간을 따로 패칭해서 배열에 넣는다. -- DB에서 패칭해서 처리한다
          // this.reservedIds = this.arrTimes.filter(tm => tm.disabled === true).map(tm => tm.id)
        })

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: date-picker 에서 오늘 이전 날자를 비활성화 시킨다.
    allowedDates (val) {
      // console.log(this.$moment().format('YYYY-MM-DD'))
      return val >= this.$moment().format('YYYY-MM-DD')
    },
    // 구분: 등록/수정
    async agree () {
      try {
        // * 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // * 선택된 시간 배열에 값이 있는가?
        if (this.selIds.length === 0 || this.selTimes.length === 0) {
          throw new Error('[ERROR-TM001]예약 시간을 선택해 주세요')
        }

        // * 선택된 시간:분이 this.form.str1, str2, str3 에 없으면 에러
        if (this.form.str1 === '' || this.form.str2 === '' || this.form.str3 === '') {
          throw new Error('[ERROR-TM002]예약 시간을 선택해 주세요')
        }

        // * this.form.date1, date2 의 시간이 '00:00:00' 이면 에러
        if (this.form.date1.substr(11, 19) === '00:00:00' || this.form.date2.substr(11, 19) === '00:00:00') {
          throw new Error('[ERROR-TM003] 예약 시간을 선택해 주세요')
        }

        // this.consoleObject(this.form) // ! TEST - this.form 을 뷰한다.
        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        // # 돌려주는 객체
        let resolve = {
          isSuccess: true, // 결과
          addWorkContent: false, // 업무추가 여부
          form: null // 업무추가등의 이유로 넘기는 객체
        }

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `<br>※ 장소 및 일시<br>• 장소: ${this.meetroomName}<br>• 날짜: ${this.pickerDate}<br>• 시간: ${this.form.str4}<br>`
        if (await pop.open('확인', msg, { color: 'info', width: 400 })) {
          // * DB 처리
          if (this.index > -1) {
            // # 수정
            const { data } = await this.$axios.post('lawork/lwc/editRsrvMeeting', this.form)
            if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
            if (data.existReserved) {
              // 이미 예약되어있는 시간이어서 불가능 한 경우
              this.actionInitResrv() // 초기화
              throw new Error('[ERROR-TM101]이미 예약된 시간입니다. 다른 시간을 선택해 주십시오.')
              //
            } else if (data.isDeleted) {
              // 삭제된 예약인 경우
              this.actionInitResrv() // 초기화
              throw new Error('[ERROR-TM201]삭제된 예약입니다. 수정할 수 없습니다.')
              //
            } else {
              // * 돌려주는 값 세팅
              resolve.isSuccess = true
              resolve.addWorkContent = this.addWorkContent // 업무추가 여부
              resolve.form = { ...this.form } // * 얉은 복사로 처리해야 한다
            }
            //
          } else {
            // # 등록
            const { data } = await this.$axios.post('lawork/lwc/addRsrvMeeting', this.form)
            if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
            if (data.existReserved) {
              // 이미 예약되어있는 시간이어서 불가능 한 경우
              this.actionInitResrv() // 초기화
              throw new Error('[ERROR-TM101]이미 예약된 시간입니다. 다른 시간을 선택해 주십시오.')
            } else {
              // * 돌려주는 값 세팅
              resolve.isSuccess = true
              resolve.addWorkContent = this.addWorkContent // 업무추가 여부
              resolve.form = { ...this.form } // * 얉은 복사로 처리해야 한다
            }
            //
          }
          //
          await this.initDialog()
          this.resolve(resolve)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `<br>정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const params = {
            id: this.form.id,
            meetDate: this.form.date1.substr(0, 10),
            cabinetId: this.form.rId,
            str3: this.form.str3
          }
          const { data } = await this.$axios.post('/lawork/lwc/delRsrvMeeting/', params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // # 돌려주는 객체
          let resolve = {
            isSuccess: true, // 결과
            addWorkContent: false, // 업무추가 여부
            form: null // 업무추가등의 이유로 넘기는 객체
          }

          await this.initDialog() // 초기화
          this.resolve(resolve)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 리스트의 체크박스를 클릭 할 때의 이벤트 핸들러
    async setCheckItem (item) {
      try {
        // console.log(item)
        // // # 수정인 경우 셀렉트를 건드리면 삭제는 불가로 한다.
        // if (this.index > -1) this.isRemovable = true

        item.isChoice = !item.isChoice

        // * 그럴리는 없겠지만 이미 예약된 사간인 경우 에러임.
        if (this.reservedIds.includes(item.id)) {
          item.isChoice = false // 선택안함상태로 돌림
          return
        }

        // ! 여기서 어려운 점은 선택되거나/해제된 시간이 순차적으로 배열에 들어가야 한다는 점이다.
        // ! 이를 컨트롤하기 위해 id 를 배열에 넣고 순차적인지 테스트한다.
        // * id 는 1 ~ 48 까지다. 즉 id=1 이면 이전은 없고 48이면 이후는 없다.
        // * [17,18] 이면 이전은 16만 가능, 이후는 19만 가능하다.
        // * 삭제시에는 중간의 시간대를 뺄 수 없는게 원칙이다. [12,13,14] 면 13을 뺄  수 없다.

        if (item.isChoice) { // #1. 체크를 할 때 - 배열에 등록할 때
          //
          if (this.selIds.length === 0 && this.selTimes.length === 0) { // * 처음 입력하는 경우
            this.selIds.push(item.id) // * this.selIds 배열에 입력
            // this.selIds.sort((a, b) => a - b) // 첫 입력이기 때문에 정렬은 필요없다.

            this.selTimes.push(item) // * this.selTimes 배열에 넣을 때는 순서를 따지지 않는다.
            //
          } else { // * 첫 입력이 아니다.
            if (this.selIds.includes(item.id)) return // 중복검사 - 중복된 경우 에러

            // * 중요: 배열에 넣으려는 아이템이 순차적인 정수값인 경우만 배열에 입력할 수 있다
            if (this.checkAddSequence(this.selIds, item.id)) {
              // # 수정인 경우 셀렉트를 건드리면 삭제는 불가로 한다.
              if (this.index > -1) this.isRemovable = true

              this.selIds.push(item.id) // * this.selIds 배열에 입력
              this.selIds.sort((a, b) => a - b) // * 순서대로 들어가지 않기 때문에 바로 정렬

              this.selTimes.push(item) // * this.selTimes 배열에 넣을 때는 순서를 따지지 않는다.
            } else {
              // * 배열에 입력할 수 없는 경우 선택안함 상태로 유지해야 함
              item.isChoice = false // 선택안함 상태로 돌림
              return
            }
          }
          //
        } else { // #2. 체크를 풀때 - 배열에서 제거할 때
          // * this.selIds 나 this.selTimes 배열이 비어있는 경우 에러
          if (this.selIds.length === 0 || this.selTimes.length === 0) return

          // * 중요: 배열에서 제거하려는 아이템이 순차적인 배열의 구조를 깨는 경우 제거불가
          if (this.checkDelSequence(this.selIds, item.id)) {
            // # 수정인 경우 셀렉트를 건드리면 삭제는 불가로 한다.
            if (this.index > -1) this.isRemovable = true

            // * 1) 선택된 시간의 id 배열에서 제거
            const index1 = this.selIds.indexOf(item.id)
            this.selIds.splice(index1, 1)
            this.selIds.sort((a, b) => a - b) // * 순서대로 들어가지 않기 때문에 바로 정렬

            // * 2) 현재 선택된 시간배열에서 제거
            const index2 = this.selTimes.findIndex(st => st.id === item.id)
            this.selTimes.splice(index2, 1)
          } else {
            // * 배열에서 제거할 수 없는 경우 선택됨 상태로 유지해야 함
            item.isChoice = true // 선택됨 상태
            return
          }
        }
        // console.log(this.selIds)

        // # 선택한 시간:분 이 있을 경우 처리
        if (this.selIds.length > 0 && this.selTimes.length > 0) {
          // * 선택한 첫시간 ~ 마지막 시간 구하기
          const fid = this.selIds[0]
          const eid = this.selIds[this.selIds.length - 1]

          const fTime = this.arrTimes[fid - 1].ft
          const eTime = this.arrTimes[eid - 1].et

          this.form.str1 = fTime // from time
          this.form.str2 = eTime // to time

          // # this.selIds 의 첫번째와 배열의 길이를 문자열로 만들어 ','로 이어붙인다.
          this.form.str3 = fid + ',' + this.selIds.length

          // * 선택시간을 뷰하는 문자열은 this.form.str4 에 들어간다.
          this.form.str4 = `${fTime.substr(0, 5)} ~ ${eTime.substr(0, 5)}`

          // * 선택한 시간:분을 date1, date2 에 매칭한다.
          this.form.date1 = `${this.pickerDate} ${fTime}`
          this.form.date2 = `${this.pickerDate} ${eTime}`

          // * 선택한 시간:분을 '09:00 ~ 10:30 (1시간 30분)' 처럼 문자열로 만드는 작업
          let viewTimeMinute = []
          const stdTime = this.selIds.length * 30 // 전체 시간 = 배열의 길이에 30분씩을 곱한 값
          const vTime = Math.floor(stdTime / 60) // 시간 = 시간을 60으로 나눈값
          const vMinute = stdTime % 60 // 분(30분단위) = 시간으로 나눈 후 나머지로 남은 30분 단위
          if (vTime > 0) {
            viewTimeMinute.push(`${vTime}시간`)
          }
          if (vMinute > 0) {
            viewTimeMinute.push(`${vMinute}분`)
          }
          if (viewTimeMinute.length > 0) {
            this.form.str4 = `${this.form.str4} (${viewTimeMinute.join(' ')})`
          }
          //
        } else {
          // # 선택 시간:분이 없는 경우 초기화
          this.form.str1 = '' // from time
          this.form.str2 = '' // to time
          this.form.str3 = '' // 시-분 값 문자열
          this.form.str4 = '' // from time to time 문자열

          this.form.date1 = `${this.pickerDate} 00:00:00` // 초기값으로 회귀
          this.form.date2 = `${this.pickerDate} 00:00:00`
        }
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정수의 순차배열에서 추가시 순서가 유지되는지 여부를 체크한다.
    // * [2,3,4] 에서 추가가능한 것은 1,5 임.
    // * 즉, 순서를 유지하기 위해 (첫번째-1)나 (마지막+1)만 가능함
    checkAddSequence (arr, item) {
      try {
        if (!arr.length) return false

        const first = arr[0] // 첫번째 요소
        const last = arr[arr.length - 1] // 마지막 요소

        // # 첫번째보다 적을때와 마지막보다 클때 2가지 경우를 체크한다.
        if (item < first) { // * 1) item 이 첫번째보다 적은 경우
          if (item === (first - 1)) { // (첫번째-1)인 경우 true
            return true
          } else {
            return false
          }
        } else if (item > last) { // * 2) item 이 마지막보다 큰 경우
          if (item === (last + 1)) { // (마지막+1)인 경우 true
            return true
          } else {
            return false
          }
        }
        return false // 그 외의 나머지는 false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정수의 순차배열에서 삭제시 순서가 유지되는지 여부를 체크한다.
    // * [1,2,3,4,5] 에서 삭제가능한 것은 1, 5 임. 즉, 첫번째와 마지막 요소만 삭제가능하다.
    // * item 이 arr 의 첫번째와 마지막 요소인 경우만 true 를 리턴한다.
    checkDelSequence (arr, item) {
      try {
        if (!arr.length) return false

        const first = arr[0] // 첫번째 요소
        const last = arr[arr.length - 1] // 마지막 요소

        // * 삭제하려는 요소가 첫번짹나 마지막이면 true 아니면 false 리턴
        if (item === first || item === last) return true

        return false // 그 외의 나머지는 false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택시간 초기화 - initSelTimeMinute() 등에서 사용됨.
    // 참고: DB 패칭 없이 시간 선택 영역만 리프레시 한다.
    setInitTimeMinute () {
      this.form.str1 = '' // * fromTime
      this.form.str2 = '' // * toTime
      this.form.str3 = ''
      this.form.str4 = ''

      this.form.date1 = this.$moment().format('YYYY-MM-DD 00:00:00') // 예약시작일시
      this.form.date2 = this.$moment().format('YYYY-MM-DD 00:00:00') // 예약종료일시

      // 사용안함: date-picker 에서 쓰이는 v-model용 변수 초기화
      // this.pickerDate = this.$moment().format('YYYY-MM-DD')

      // ! 중요: 시간관련 변수(배열) 초기화
      this.arrTimes.map(arr => {
        arr.isChoice = false
        arr.disabled = false
      })
      this.selTimes = [] // 선택된 시간배열 초기화
      this.selIds = [] // 선택된 시간 id 배열 초기화

      // * 예약시간 배열은 초기화하면 안된다!
      // this.reservedIds = [] // 예약된 시간 id 배열 초기화

      // * 예약된 시간이 있는 경우 선택불가 처리
      if (this.reservedIds.length > 0) {
        this.reservedIds.forEach(tId => {
          this.arrTimes[tId - 1].disabled = true
        })
      }
    },
    // 구분: 뷰해주는 시간 초기화
    initSelTimeMinute () {
      try {
        // 선택이 없으면 아무것도 안하고 리턴
        if (this.selIds.length === 0 || this.selTimes.length === 0) return

        this.setInitTimeMinute()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // ? 구분: 예약 현황을 패칭하는 함수 - DB 패칭이 가장 큰 역할임.
    // * 주의: 상단의 setInitTimeMinute() 함수와 비슷하지만 용도가 다르다. 헷갈리지 말자.
    // * open(), saveDate1() 에서 사용한다.
    async getReservedDateTime () {
      try {
        if (!this.form.rId) return // ! 회의실 아아디가 없으면 안된다.

        this.timeOverlay = true // 오버레이 활성

        // * 초기화
        this.selTimes = [] // 선택된 시간배열 초기화
        this.selIds = [] // 선택된 시간 id 배열 초기화
        this.reservedIds = [] // 예약된 시간 id 배열 초기화

        // 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(600 - Math.floor(Math.random() * 200))

        // * DB 처리 - 예약관리현황을 패칭한다. 회의실 아이디와 날자를 넘긴다.
        const { data } = await this.$axios.get(`/lawork/lwc/getRsrvTimes/${this.form.rId}/${this.pickerDate}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // * 예약된 시간 처리 - 패칭된 데이터로 예약현황을 처리한다.
        // this.reservedIds = [22, 23, 26, 27, 28] // 예약된 시간의 아이디(this.arrTimes) 배열
        this.rsrved = data.resvStr // '000000000000000000000000000000000000000000000000' // mock 데이터
        const tmpArr = this.rsrved.split('') // 배열로 변환
        tmpArr.forEach((item, idx) => {
          if (item === '1') { // '1' 인 경우(예약된 시간임)
            this.reservedIds.push(idx + 1) // (인덱스 + 1)을 배열에 넣는다.
          }
        })

        // * 예약된 시간이 있는 경우 선택불가 처리
        if (this.reservedIds.length > 0) {
          this.reservedIds.forEach(tId => {
            this.arrTimes[tId - 1].disabled = true
          })
        }

        this.$nextTick(() => {
          // * 스크롤 자동 이동 - 08:00 부터 시작하게
          document.getElementById('w-scrollTop').scrollTop = 580
        })

        this.timeOverlay = false // 오버레이 비활성
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 이것도 초기화 - saveDate1(), agree() 에서 사용
    actionInitResrv () {
      try {
        // # 중요: 초기화 처리 영역
        // * this.arrTimes 초기화
        this.arrTimes.map(arr => {
          arr.isChoice = false
          arr.disabled = false
        })

        this.form.date1 = this.$moment(this.pickerDate).format('YYYY-MM-DD 00:00:00') // 예약시작일시
        this.form.date2 = this.$moment(this.pickerDate).format('YYYY-MM-DD 00:00:00') // 예약종료일시
        this.form.str1 = '' // * fromTime
        this.form.str2 = '' // * toTime
        this.form.str3 = '' // * '21,3' 과 같은 문자열
        this.form.str4 = '' // * from time to time 문자열
        // # 여기까지 초기화 처리

        // * 중요: 예약현황을 패칭해서 적용한다.
        this.getReservedDateTime()
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 예약시작일자 셀렉트 선택시 저장 함수
    // * 중요: 날짜변경시 예약상황과 함께 초기화 해야할 변수가 많다.
    saveDate1 (date) {
      try {
        // console.log(`this.pickerDate: ${this.pickerDate}`)
        this.$refs.date1Selector.save(date)

        this.actionInitResrv() // 초기화

        // this.consoleObject(this.form) // ! TEST - this.form 을 뷰한다.

        // 사용안함: 기존 선택된 시간:분이 있다면 this.form.date1, date2 에 반영
        // * 날자가 변경되면 기존 예약된 시간도 있을거고 하니 초기화 하는게 맞다.
        // if (this.form.str1 && this.form.str2) {
        //   this.form.date1 = `${date} ${this.form.str1}`
        //   this.form.date2 = `${date} ${this.form.str2}`
        // }
        //
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
.w-theme--light.w-calendar-daily {
    background-color: #fff;
    border-left: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}
.w-calendar-daily {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}
.w-calendar-daily__head {
    flex: none;
    display: flex;
}
.w-theme--light.w-calendar-daily .w-calendar-daily__intervals-head {
    border-right: 1px solid #e0e0e0;
}
.w-calendar-daily__intervals-head {
    flex: none;
    position: relative;
}
.w-calendar-daily__body {
    flex: 1 1 60%;
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction: column;
}
.w-calendar-daily__scroll-area {
    overflow-y: scroll;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
}
.w-calendar-daily__pane {
    width: 100%;
    overflow-y: hidden;
    flex: none;
    display: flex;
    align-items: flex-start;
}
.w-calendar-daily__day-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}
.w-theme--light.w-calendar-daily .w-calendar-daily__intervals-body {
    border-right: 1px solid #e0e0e0;
}
.w-calendar-daily__intervals-body {
    flex: none;
    user-select: none;
}
.w-calendar-daily__interval {
    text-align: right;
    padding-right: 8px;
    border-bottom: none;
    position: relative;
}
.w-theme--light.w-calendar-daily .w-calendar-daily__intervals-body .w-calendar-daily__interval-text {
    color: #424242;
}
.w-calendar-daily__interval-text {
    display: block;
    position: relative;
    top: -6px;
    font-size: 10px;
    padding-right: 4px;
}
.w-theme--light.w-calendar-daily .w-calendar-daily__day {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}
.w-calendar-daily__day {
    flex: 1;
    width: 0;
    position: relative;
}
.w-theme--light.w-calendar-daily .w-calendar-daily__day-interval:first-child {
    border-top: none!important;
}
.w-theme--light.w-calendar-daily .w-calendar-daily__day-interval {
    border-top: 1px solid #e0e0e0;
}
</style>
